<template>
  <div class="hidden md:block text-gray-600">
    {{ $t("app.showing_entries", { first, last: displayedLast, total }) }}
  </div>
</template>

<script>
export default {
  props: {
    // Current page
    page: {
      type: [Number, String],
      default: 1
    },
    // Elements per page
    perPage: {
      type: [Number, String],
      default: 10
    },
    // Total elements
    total: {
      type: [Number, String],
      default: 150
    }
  },
  computed: {
    first() {
      return this.last - this.perPage + 1;
    },
    last() {
      return this.page * this.perPage;
    },
    displayedLast() {
      return this.last > this.total ? this.total : this.last;
    }
  }
};
</script>
