<template>
  <select
    :value="modelValue"
    class="w-20 form-select box mt-3 sm:mt-0"
    @input="$emit('update:modelValue', $event.target.value)"
  >
    <option v-for="option in options" :key="option" :value="option">
      {{ option }}
    </option>
  </select>
</template>

<script>
export default {
  props: {
    modelValue: {
      type: [Number, String],
      default: 10
    }
  },
  emits: ["update:modelValue"],
  data() {
    return {
      options: [10, 20, 50, 100]
    };
  }
};
</script>
