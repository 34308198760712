import { useStore } from "@/store";

/**
 * @param {Ref<Object>} pagination
 * @param {Function} getData
 * @returns {Object}
 */
export default function usePaginate(pagination, getData) {
  // MISC
  const store = useStore();

  // METHODS
  const onUpdateCurrentPage = current_page => {
    if (current_page === pagination.value.current_page) {
      return;
    }

    pagination.value.current_page = current_page;
    store.dispatch("queries/setCurrentPage", current_page);

    if (getData) {
      getData();
    }
  };

  const onUpdatePerPage = per_page => {
    pagination.value.per_page = per_page;
    pagination.value.current_page = 1;

    store.dispatch("queries/setCurrentPage", 1);
    store.dispatch("queries/setPerPage", per_page);

    if (getData) {
      getData();
    }
  };

  return {
    onUpdateCurrentPage,
    onUpdatePerPage
  };
}
