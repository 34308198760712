<template>
  <div>
    <div class="alert alert-secondary show flex items-center" role="alert">
      <i class="fal fa-lg mr-2" :class="icon" />
      <slot>
        {{ text }}
      </slot>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    icon: {
      type: String,
      default: "fa-info-circle"
    },
    text: {
      type: String,
      default: ""
    }
  }
};
</script>
