<template>
  <div>
    <VTitle class="flex justify-baseline items-center route-title">
      {{ $t("app.collections", 2) }}

      <VHint class="ml-4 text-base">
        {{ $t("hints.learning_collections") }}
      </VHint>
    </VTitle>

    <div v-if="isLoading" class="flex justify-center">
      <LoadingIcon icon="three-dots" class="w-8 h-8" />
    </div>

    <VAlert v-else-if="data.length === 0" :text="$t('app.no_results_found')" />

    <div v-else>
      <div class="grid grid-cols-3 gap-8">
        <div v-for="training in data" :key="training?.id">
          <CollectionCard
            :training="training"
            @click="onClickRedirectToTraining(training?.id)"
          >
            <template #status>
              <VChip
                v-if="training.registration_status"
                :text="$t(`app.${training?.registration_status}`)"
                :class="getStatusColor(training.registration_status)"
              />
            </template>
          </CollectionCard>
        </div>
      </div>

      <FullPagination
        :pagination="pagination"
        @update:current_page="onUpdateCurrentPage"
        @update:per_page="onUpdatePerPage"
      />
    </div>
  </div>
</template>

<script>
import { onMounted } from "vue";
import { useI18n } from "vue-i18n";
import { useRouter } from "vue-router";
// Composables
import usePaginate from "@/composables/usePaginate";
import useColor from "@/composables/useColor";
import useReadMultiple from "@/composables/useReadMultiple";
// Components
import VTitle from "@/components/VTitle";
import VAlert from "@/components/VAlert";
import VChip from "@/components/VChip";
import FullPagination from "@/components/tables/FullPagination";
import CollectionCard from "@/views/learning/collections/CollectionCard";
import VHint from "@/components/VHint";

export default {
  components: {
    VTitle,
    VAlert,
    VHint,
    FullPagination,
    CollectionCard,
    VChip
  },
  setup() {
    // Misc
    const { t } = useI18n();
    const router = useRouter();

    // Constants
    const documentTitle = `${t("app.collections", 2)} - ${t("app.learning")}`;

    // Composables
    const { read, data, pagination, isLoading } = useReadMultiple();
    const { getStatusColor } = useColor();

    // Methods
    const getData = async () => {
      await read({
        endpoint: "learning.collections"
      });
    };

    const onClickRedirectToTraining = id => {
      router.push({
        name: "learning-collections-details",
        params: { id }
      });
    };

    // eslint-disable-next-line
    const { onUpdatePerPage, onUpdateCurrentPage } = usePaginate(pagination, getData);

    // Lifecycle Hooks
    onMounted(async () => {
      await getData();
    });

    return {
      documentTitle,
      onClickRedirectToTraining,
      // useReadMultiple
      isLoading,
      data,
      pagination,
      // useColor
      getStatusColor,
      // usePaginate
      onUpdatePerPage,
      onUpdateCurrentPage
    };
  }
};
</script>
