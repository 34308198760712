<template>
  <div
    class="col-span-12 flex flex-wrap sm:flex-row sm:flex-nowrap justify-between items-center mt-5"
    :class="{ 'intro-y': hasAnimation }"
  >
    <CurrentPageElements
      :page="current_page"
      :per-page="per_page"
      :total="total"
    />

    <VPagination
      :model-value="current_page"
      :per-page="per_page"
      :total="total"
      @update:modelValue="$emit('update:current_page', $event)"
    />

    <ElementsPerPage
      v-if="!hideElementsPerPage"
      :model-value="per_page"
      @update:modelValue="$emit('update:per_page', $event)"
    />
  </div>
</template>

<script>
import { computed } from "vue";
// Composables
import useAnimation from "@/composables/useAnimation";
// Components
import VPagination from "@/components/tables/VPagination";
import ElementsPerPage from "@/components/tables/ElementsPerPage";
import CurrentPageElements from "@/components/tables/CurrentPageElements";

export default {
  components: {
    VPagination,
    ElementsPerPage,
    CurrentPageElements
  },
  props: {
    pagination: {
      type: Object,
      default: () => ({
        current_page: 1,
        per_page: 20,
        total: 0
      })
    },
    hideElementsPerPage: {
      type: Boolean,
      default: false
    }
  },
  emits: ["update:current_page", "update:per_page"],
  setup(props) {
    // Composables
    const { hasAnimation } = useAnimation();

    // Computed
    const current_page = computed(() => props.pagination?.current_page);
    const per_page = computed(() => props.pagination?.per_page);
    const total = computed(() => props.pagination?.total);

    return {
      current_page,
      per_page,
      total,
      // useAnimation
      hasAnimation
    };
  }
};
</script>
