import { ref, computed } from "vue";
import { useStore } from "@/store";

export default function usePagination() {
  // MISC
  const store = useStore();

  // COMPUTED
  const userPerPage = computed(() => parseInt(store.getters["user/per_page"]));

  // DATA
  const pagination = ref({
    current_page: 1,
    per_page: userPerPage.value
  });

  return {
    pagination
  };
}
