<template>
  <div>
    <ul class="pagination">
      <li v-if="page !== 1">
        <a class="pagination__link" @click="$emit('update:modelValue', 1)">
          <ChevronsLeftIcon class="w-4 h-4" />
        </a>
      </li>

      <li v-if="previousPage">
        <a
          class="pagination__link"
          @click="$emit('update:modelValue', previousPage)"
        >
          <ChevronLeftIcon class="w-4 h-4" />
        </a>
      </li>

      <li v-if="!expandPreviousPages && previousPages.length">
        <a class="pagination__link" @click="expandPreviousPages = true">
          ...
        </a>
      </li>

      <div v-if="expandPreviousPages && previousPages.length" class="flex">
        <li v-for="(p, index) in previousPages" :key="`previous-${index}`">
          <a class="pagination__link" @click="$emit('update:modelValue', p)">
            {{ p }}
          </a>
        </li>
      </div>

      <li v-if="previousPage">
        <a
          class="pagination__link"
          @click="$emit('update:modelValue', previousPage)"
        >
          {{ previousPage }}
        </a>
      </li>

      <li>
        <a
          class="pagination__link pagination__link--active"
          @click="$emit('update:modelValue', page)"
        >
          {{ page }}
        </a>
      </li>

      <li v-if="nextPage">
        <a
          class="pagination__link"
          @click="$emit('update:modelValue', nextPage)"
        >
          {{ nextPage }}
        </a>
      </li>

      <li v-if="!expandNextPages && nextPages.length">
        <a class="pagination__link" @click="expandNextPages = true">
          ...
        </a>
      </li>

      <div v-if="expandNextPages && nextPages.length" class="flex">
        <li v-for="(p, index) in nextPages" :key="`next-${index}`">
          <a class="pagination__link" @click="$emit('update:modelValue', p)">
            {{ p }}
          </a>
        </li>
      </div>

      <li v-if="nextPage">
        <a
          class="pagination__link"
          @click="$emit('update:modelValue', nextPage)"
        >
          <ChevronRightIcon class="w-4 h-4" />
        </a>
      </li>

      <li v-if="totalPages && page !== totalPages">
        <a
          class="pagination__link"
          @click="$emit('update:modelValue', totalPages)"
        >
          <ChevronsRightIcon class="w-4 h-4" />
        </a>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  props: {
    // Current page
    modelValue: {
      type: [String, Number],
      default: 1
    },
    // Elements per page
    perPage: {
      type: [String, Number],
      default: 10
    },
    // Total elements
    total: {
      type: [String, Number],
      default: 150
    }
  },
  emits: ["update:modelValue"],
  data() {
    return {
      expandPreviousPages: false,
      expandNextPages: false
    };
  },
  computed: {
    page() {
      return this.modelValue;
    },
    totalPages() {
      return Math.ceil(this.total / this.perPage);
    },
    previousPage() {
      const previousPage = this.page - 1;
      return previousPage <= 0 ? 0 : previousPage;
    },
    previousPages() {
      if (this.previousPage === 0) {
        return [];
      }

      const pages = [];

      for (let i = this.previousPage - 1; i >= this.previousPage - 3; i--) {
        if (i > 0) {
          pages.unshift(i);
        }
      }

      return pages;
    },
    nextPage() {
      const nextPage = this.page + 1;
      return nextPage > this.totalPages ? 0 : nextPage;
    },
    nextPages() {
      if (this.nextPage === 0) {
        return [];
      }

      const pages = [];

      for (let i = this.nextPage + 1; i <= this.nextPage + 3; i++) {
        if (i <= this.totalPages) {
          pages.push(i);
        }
      }

      return pages;
    }
  }
};
</script>
