import { ref } from "vue";
// Composables
import usePagination from "@/composables/usePagination";
import useRequest from "@/composables/useRequest";

export default function useReadMultiple() {
  const { pagination } = usePagination();

  // Data
  const data = ref([]);

  // Methods
  const { isRequesting: isLoading, request } = useRequest();

  const read = async ({ endpoint, pathParams = {}, queryParams = {} }) => {
    const response = await request({
      endpoint,
      pathParams,
      queryParams: {
        ...queryParams,
        ro_p: pagination.value.per_page,
        page: pagination.value.current_page
      },
      showToaster: false
    });

    data.value = response?.payload?.data || [];

    // eslint-disable-next-line
    const { per_page = 0, current_page = 1, total = 0 } = response?.payload?.pagination ?? {};
    pagination.value.per_page = per_page;
    pagination.value.current_page = current_page;
    pagination.value.total = total;
  };

  return {
    data,
    // usePagination
    pagination,
    // useRequest
    isLoading,
    read
  };
}
