<template>
  <div class="flex flex-wrap items-start">
    <div class="flex">
      <slot name="label">
        <div class="label colon">
          {{ label }}
        </div>
      </slot>
    </div>

    <slot name="value">
      <div>
        {{ value }}
      </div>
    </slot>
  </div>
</template>

<script>
export default {
  props: {
    label: {
      type: String,
      default: ""
    },
    value: {
      type: [String, Number],
      default: ""
    }
  }
};
</script>
